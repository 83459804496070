<template>
    <el-form ref="form" :model="form" label-width="140px" class="elForm" :rules="rules">
        <el-form-item label="广告类型：" prop="type">
            <el-select v-model="form.type" style="width: 700px">
                <el-option label="图片" :value="1"></el-option>
                <el-option label="视频" :value="2"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="广告封面：" prop="ad_picture">
            <ReadyUploadSource @getSource="val => (form.ad_picture = val.path)" :path="form.ad_picture"
                @removeThis="() => (form.ad_picture = '')">
            </ReadyUploadSource>
        </el-form-item>
        <el-form-item label="广告视频：" prop="ad_video" v-if="form.type == 2">
            <ReadyUploadSource @getSource="val => (form.ad_video = val.path)" @removeThis="() => (form.ad_video = '')"
                :path="form.ad_video" type="video"></ReadyUploadSource>
        </el-form-item>
        <el-form-item label="VR链接：" prop="vr_url" v-else>
            <el-input placeholder="请输入VR链接" type="text" v-model="form.vr_url" style="width: 700px;"></el-input>
        </el-form-item>
        <el-form-item label="上架时间：" prop="show_time">
            <el-date-picker v-model="form.show_time" type="datetime" placeholder="选择日期时间" style="width: 700px;">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="到期时间：" prop="out_time">
            <el-date-picker v-model="form.out_time" type="datetime" placeholder="选择日期时间" style="width: 700px;">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="上架城市：" prop="city_id">
            <el-cascader v-model="form.city_id" :options="allAreaList" style="width: 700px;"
                :props="{ value: 'id', label: 'name', children: '_child', emitPath: false }"
                :disabled="is_edit"></el-cascader>
        </el-form-item>
        <el-form-item label="展示/下架：" prop="status">
            <el-switch v-model="form.status" :active-value="1" :inactive-value="0">
            </el-switch>
        </el-form-item>
        <Preservation @preservation="onSubmit"></Preservation>
    </el-form>
</template>

<script>
import Preservation from '@/components/preservation';
import ReadyUploadSource from '@/components/readyUploadSource';
export default {
    components: {
        Preservation,
        ReadyUploadSource
    },
    data () {
        return {
            id: '',
            form: {
                type: 1,
                ad_picture: '',
                ad_video: '',
                vr_url: '',
                city_id: '',
                show_time: '',
                out_time: '',
                status: 1,
                sort: 0
            },
            rules: {
                image: [
                    { required: true, message: '请上传广告封面', trigger: 'change' },
                ],
                ad_video: [
                    { required: true, message: '请上传广告视频', trigger: 'change' },
                ],
                city_id: [
                    { required: true, message: '请选择上架城市', trigger: 'change' },
                ],
            },
            is_edit: false,
            allAreaList: []
        };
    },
    async created () {
        await this.getArea()
        if (this.$route.query.info) {
            let info = JSON.parse(this.$route.query.info)
            this.form = {
                type: info.type,
                ad_picture: info.ad_picture,
                ad_video: info.ad_video,
                vr_url: info.vr_url,
                city_id: info.city_id,
                show_time: info.show_time * 1000,
                out_time: info.out_time * 1000,
                status: info.status,
                sort: info.sort,
                id: info.id
            }
            this.is_edit = true
        }
    },
    methods: {
        // 获取省市区信息
        getArea () {
            this.$axios
                .get(this.$api.shopDiy.areaList, {
                    tree: 1,
                    level: 1,
                })
                .then(res => {
                    if (res.code == 0) {
                        this.allAreaList = res.result.list;
                    }
                });
        },
        onSubmit () {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let form = { ...this.form }
                    form.show_time = form.show_time / 1000
                    form.out_time = form.out_time / 1000

                    let url = this.is_edit ? this.$api.repair.Ad.edit : this.$api.repair.Ad.add

                    this.$axios.post(url, form).then(res => {
                        if (res.code == 0) {
                            this.$message.success((this.is_edit ? '编辑' : '添加') + '成功');
                            this.$router.go(-1)
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.elForm {
    background-color: #fff;
    padding: 30px 30px 80px;
}

.centerBtn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tips {
    color: #999;
    margin-left: 20px;
}
</style>
